import React, { ReactElement } from "react";
import { graphql, useStaticQuery } from "gatsby";
import fontawesome from "@fortawesome/fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Breadcrumbs,
  BannerHeader,
  FixedSharpImageFile,
  FluidSharpImageFile,
} from "@ymcansw-camping/common";
import Layout from "../components/layout";

fontawesome.library.add(faPhone, faEnvelope);

type ContactData = {
  readonly bannerHeaderImage: FixedSharpImageFile;
  readonly contactImage: FluidSharpImageFile;
};

export default function ContactPage(): ReactElement {
  const { bannerHeaderImage } = useStaticQuery<ContactData>(graphql`
    {
      bannerHeaderImage: file(relativePath: { eq: "img/contact-bg.jpg" }) {
        ...GeneralPageLayout_bannerHeaderImage
      }
    }
  `);

  return (
    <Layout
      meta={{
        title: "Contact Us",
        description:
          "Our team looks forward to working with you as a partner in delivering Outdoor Education and Camping programs for children and families in our community.",
      }}
    >
      <BannerHeader title="CONTACT US" image={bannerHeaderImage} />
      <div className="page-content contact-page">
        <div className="container">
          <div className="page-content-header">
            <div className="row">
              <div className="col-xs-12 col-sm-7 col-lg-8">
                <Breadcrumbs items={["Contact us"]} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <div className="content">
                <div className="content-block">
                  <h2 className="mt0">Contact us</h2>
                  <p>
                    Our team looks forward to working with you as a partner in
                    delivering Outdoor Education and Camping programs for
                    children and families in our community.
                  </p>
                  <p>
                    For more information, request a quote, or make a booking,
                    please reach out to our team via phone or email:
                  </p>

                  <ul className="icons-list">
                    <li>
                      <FontAwesomeIcon
                        icon="phone"
                        className="flip-horizontal"
                      />
                      <a href="tel:1800024097">1800 024 097</a>
                    </li>
                    <li>
                      <FontAwesomeIcon icon="envelope" />
                      <a href="mailto:admin.yarramundi@ymcansw.org.au">
                        admin.yarramundi@ymcansw.org.au
                      </a>
                    </li>
                    <li className="contact-details-address">
                      <FontAwesomeIcon icon="map-marker" />
                      <span>316 Springwood Rd, Yarramundi NSW 2753</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
